var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "content-header" },
        [
          _c(
            "b-col",
            {
              staticClass: "content-header-left mb-2",
              attrs: { cols: "12", md: "9" }
            },
            [
              _c(
                "b-row",
                { staticClass: "breadcrumbs-top" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h2",
                      {
                        staticClass: "content-header-title float-left pr-1 mb-0"
                      },
                      [_vm._v(" Reports ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "breadcrumb-wrapper" },
                      [
                        _c(
                          "b-breadcrumb",
                          [
                            _c(
                              "b-breadcrumb-item",
                              { attrs: { to: "/apps/reports/type" } },
                              [
                                _c("feather-icon", {
                                  staticClass: "align-text-top",
                                  attrs: { icon: "HomeIcon", size: "16" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-breadcrumb-item",
                              { on: { click: _vm.goBack } },
                              [_vm._v(" Reports ")]
                            ),
                            _c("b-breadcrumb-item", { attrs: { active: "" } }, [
                              _vm._v(" Parking Permit ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.reportData &&
      _vm.clientOptions &&
      (_vm.$can("read", _vm.abilityRequired) ||
        _vm.$can("create", _vm.abilityRequired) ||
        _vm.$can("update", _vm.abilityRequired))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Report Details ")])]),
              _c(
                "b-card-body",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var handleSubmit = ref.handleSubmit
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.validateForm)
                                    }
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.report_type,
                                        expression: "reportData.report_type"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "report_type"
                                    },
                                    domProps: {
                                      value: _vm.reportData.report_type
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "report_type",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.time_zone,
                                        expression: "reportData.time_zone"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "time_zone"
                                    },
                                    domProps: {
                                      value: _vm.reportData.time_zone
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "time_zone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.reportData.client_display_name,
                                        expression:
                                          "reportData.client_display_name"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "client_display_name"
                                    },
                                    domProps: {
                                      value: _vm.reportData.client_display_name
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "client_display_name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.attachment_list,
                                        expression: "reportData.attachment_list"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "attachment_list"
                                    },
                                    domProps: {
                                      value: _vm.reportData.attachment_list
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "attachment_list",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "PERMIT #",
                                                "label-for": "report_id"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "report_id" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "report_id",
                                                              disabled: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .report_id,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "report_id",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.report_id"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Client",
                                                "label-for": "clientname"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "clientname" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "clientname",
                                                              disabled: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .client_display_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "client_display_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.client_display_name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site",
                                                "label-for": "sitename"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "sitename" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "sitename",
                                                              disabled: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .site_address,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "site_address",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.site_address"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Suite",
                                                "label-for": "suite"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "suite" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "suite",
                                                              disabled: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .suite,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "suite",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.suite"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Email Address",
                                                "label-for": "email"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "email" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "email",
                                                              disabled: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .email,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "email",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.email"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "License Plate #",
                                                "label-for": "licence_plate"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "licence_plate"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "licence_plate",
                                                              disabled: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .licence_plate,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "licence_plate",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.licence_plate"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Vehicle Make",
                                                "label-for": "vehicle_make"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "vehicle_make" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "vehicle_make",
                                                              disabled: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .vehicle_make,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "vehicle_make",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.vehicle_make"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Vehicle Model",
                                                "label-for": "vehicle_model"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "vehicle_model"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "vehicle_model",
                                                              disabled: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .vehicle_model,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "vehicle_model",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.vehicle_model"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "From",
                                                "label-for": "from_dt"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "from_dt",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "vehicle_model",
                                                              disabled: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .from_dt,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "from_dt",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.from_dt"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Expires",
                                                "label-for": "expires_dt"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "expires_dt",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "vehicle_model",
                                                              disabled: true
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .expires_dt,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "expires_dt",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.expires_dt"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c("b-col", { attrs: { md: "6" } }, [
                                        _c("ul", [
                                          _c("li", [
                                            _vm._v(
                                              "Please check this permit for accuracy."
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "Pass valid only for the times and dates indicated."
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "Incorrectly registered vehicles may be tagged and/or towed."
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "Residents are not permitted to park in visitors parking."
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "Visitors are permitted to park only in areas designated to visitors parking."
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "Visitors must comply with the declaration, bylaws and rules."
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "Vehicles parked in the visitors parking are left at owners risk."
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "The staff and agents of the corporation will not accept responsibility for loss or damage howsoever caused."
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "Permit is to be used by visitors and is not to be used by residents."
                                            )
                                          ]),
                                          _c("li", [
                                            _vm._v(
                                              "Permits must be clearly displayed at all times."
                                            )
                                          ])
                                        ]),
                                        _c("strong", [
                                          _vm._v(
                                            "VEHICLES REMAINING AFTER PASS HAS EXPIRED MAY BE TOWED AT OWNERS EXPENSE"
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm.reportID !== null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.reportData &&
                                              _vm.clientOptions &&
                                              _vm.$can(
                                                "update",
                                                _vm.abilityRequired
                                              ) &&
                                              _vm.reportData.username ===
                                                _vm.userData.username
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            type: "submit",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Save Changes "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.$can(
                                                "read",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant:
                                                              "secondary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.printReport
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Print Report "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm.$can(
                                            "update",
                                            _vm.abilityRequired
                                          )
                                            ? _c(
                                                "b-row",
                                                { staticClass: "mt-4" },
                                                [
                                                  !_vm.reportData.approved
                                                    ? _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Approve "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "secondary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Unapprove "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      40129709
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }